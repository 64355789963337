const tableColumns = [
  {
    key: 'photo',
    label: 'Фото товара',
  },
  {
    key: 'price',
    label: 'Сумма за товар',
  },
  {
    key: 'postcard_text',
    label: 'Текст открытки',
  },
  {
    key: 'composition_comment',
    label: 'Комментарий к композиции',
  },
  {
    key: 'actions',
    label: '',
  },
]

const modalMode = {
  edit: false,
  add: false,
  delete: false,
}

const modalConfig = {
  title: '',
  success: '',
  danger: '',
}

export { tableColumns, modalConfig, modalMode }
