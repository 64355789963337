<template>
  <section>
    <order-card></order-card>
    <template v-if="isEditPage">
      <order-items></order-items>
    </template>
  </section>
</template>

<script>
import OrderCard from './OrderCard'
import OrderItems from './OrderItems'

export default {
  name: 'OrderBaseItem',

  components: {
    OrderCard,
    OrderItems,
  },
  computed: {
    isEditPage() {
      if (this.$route.params.id && this.$route.params.id !== 'new') return true
      else return false
    },
  },
}
</script>
