<template>
  <app-card
    @admit="isEdit ? onEdit() : onAdd()"
    @decline="onCansel"
    v-bind="{
      disabled
    }"
  >
    <template
      #more
      v-if="isEdit"
    >
      <div class="d-flex justify-content-between justify-content-md-end mb-1 order-info">
        <div v-if="form.number_daily" class="mr-1">
          <span class="font-weight-bold text-danger">
            №{{ form.number_daily }}
          </span>
        </div>
        <div class="mr-2 mb-1">
          <span>Дата заказа: {{ form.created_at }}</span>
        </div>
        <div
          class="order-info-icon"
          @click="onDeleteModal"
        >
          <feather-icon
            icon="TrashIcon"
            color="red"
            size="1.5x"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="col-12 col-lg-6">
        <label for="item-source">Источник заказа</label>
        <b-form-select
          :disabled=disabled
          ref="source"
          id="item-source"
          v-model="form.source.id"
          :options="sourceOptions"
          :state="validation.source"
          class="mb-1"
        ></b-form-select>

        <label for="item-number">Номер заказа</label>
        <b-form-input
          :disabled=disabled
          ref="number"
          id="item-number"
          v-model="form.number"
          class="mb-1"
          :state="validation.number"
        ></b-form-input>
        <section>
          <label for="item-name">ФИО заказчика</label>
          <b-form-input
            :disabled=disabled
            ref="client_full_name"
            id="item-name"
            v-model="form.client.full_name"
            class="mb-1"
            :state="validation.client_full_name"
          ></b-form-input>

          <label for="item-client_phone">Телефон заказчика</label>
          <b-form-input
            :disabled=disabled
            ref="client_phone"
            id="item-client_phone"
            @input="onLoadClients"
            v-model="form.client.phone"
            v-mask="phoneMask"
            class="mb-1"
            :state="validation.client_phone"
          ></b-form-input>
        </section>

        <section>
          <label for="item-name">ФИО получателя</label>
          <b-form-input
            :disabled=disabled
            id="item-name"
            v-model="form.recipient.full_name"
            class="mb-1"
          ></b-form-input>

          <label for="item-client_phone">Телефон получателя</label>
          <b-form-input
            :disabled=disabled
            id="item-client_phone"
            @input="onLoadRecipients"
            v-model="form.recipient.phone"
            v-mask="phoneMask"
            class="mb-1"
          ></b-form-input>
        </section>

        <label for="item-payment_status">Статус оплаты</label>
        <b-form-select
          :disabled=disabled
          id="item-payment_status"
          v-model="form.pay_status"
          :options="orderPaymentStatusCollection"
          class="mb-1"
        ></b-form-select>

        <label for="item-status">Статус заказа</label>
        <b-form-select
          :disabled=disabled
          id="item-status"
          v-model="form.status"
          :options="statusesCollection"
          class="mb-1"
        ></b-form-select>

        <label for="item-florist">Ответственный флорист</label>
        <b-form-select
          :disabled=disabled
          id="item-florist"
          v-model="form.florist_id"
          :options="floristOptions"
          class="mb-1"
        ></b-form-select>
      </div>
      <div class="col-12 col-lg-6">
        <label for="item-delivery_type">Тип доставки</label>
        <b-form-select
          :disabled=disabled
          id="item-delivery_type"
          v-model="form.delivery_status"
          :options="deliveryTypeCollection"
          class="mb-1"
        ></b-form-select>
        <b-row class="mb-1">
          <b-col>
            <label for="order-date">Дата доставки</label>
            <b-datepicker
              :disabled=disabled
              ref="delivery_date"
              id="order-date"
              v-model="form.delivery_date"
              placeholder="выберите дату"
              :state="validation.delivery_date"
              :startWeekday="1"
              locale="ru"
              :dateFormatOptions="dateFormat"
              labelHelp=""
              label-no-date-selected="Дата не выбрана"
            ></b-datepicker>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col class="col-12 col-md-6 col-lg-6">
            <label>Время c:</label>
            <div class="d-flex align-center">
              <b-form-input
                type="number"
                v-mask="'##'"
                class="d-block mr-1"
                placeholder="часы"
                v-model="form.time.from.h"
                :state="validateTime.from.h"
                :disabled="(form.delivery_date ? false : true) || disabled"
              ></b-form-input>
              <b-form-input
                type="number"
                v-mask="'##'"
                class="d-block"
                placeholder="минуты"
                v-model="form.time.from.m"
                :state="validateTime.from.m"
                :disabled="(form.time.from.h ? false : true) || disabled"
              ></b-form-input>
            </div>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-6">
            <label>Время по:</label>
            <div class="d-flex align-center">
              <b-form-input
                type="number"
                v-mask="'##'"
                class="d-block mr-1"
                placeholder="часы"
                v-model="form.time.to.h"
                :state="validateTime.to.h"
                :disabled="(form.delivery_date ? false : true) || disabled"
              ></b-form-input>
              <b-form-input
                type="number"
                v-mask="'##'"
                class="d-block"
                placeholder="минуты"
                v-model="form.time.to.m"
                :state="validateTime.to.m"
                :disabled="(form.time.to.h ? false : true) || disabled"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <label for="item-delivery_address">Адрес доставки</label>
        <b-form-input
          ref="address"
          id="item-delivery_address"
          :value="form.delivery_address"
          @input="onSuggestions"
          class="app-suggest mb-1"
          :disabled="(form.delivery_status === 0) ||  disabled"
        ></b-form-input>

        <label for="item-delivery_comment">Комментарий к адресу</label>
        <b-form-textarea
          id="item-delivery_comment"
          v-model="form.delivery_comment"
          class="mb-1"
          :disabled="(form.delivery_status === 0) || disabled"
        ></b-form-textarea>

        <template v-if="form.delivery_status !== 1">
          <label for="item-delivery_service_id">Курьерская служба</label>
          <b-form-select
            id="item-delivery_service_id"
            v-model="form.delivery_service_id"
            @input="onDeliveryServiceId"
            :options="deliveryOptions"
            class="mb-1"
            :disabled="(form.delivery_status === 0) || disabled"
          ></b-form-select>
        </template>

        <template v-if="form.delivery_status === 1">
          <label for="item-courier_id">Курьер на заказ</label>
          <b-form-select
            id="item-courier_id"
            v-model="form.courier_id"
            :options="courierOptions"
            class="mb-1"
            :disabled="(form.delivery_status === 0) || disabled"
          ></b-form-select>
        </template>

        <template v-if="form.delivery_status === 2">
          <label for="item-courier_phone">Телефон курьера</label>
          <b-form-input
            id="item-courier_phone"
            v-model="form.courier_phone"
            v-mask="phoneMask"
            class="mb-1"
            :disabled="(form.delivery_status === 0) || disabled"
          ></b-form-input>
        </template>

        <label for="item-delivery_price">Стоимость доставки</label>
        <b-form-input
          type="number"
          id="item-delivery_price"
          v-model="form.delivery_price"
          class="mb-1"
          :disabled="(form.delivery_status === 0) || disabled"
        ></b-form-input>

        <label for="item-manager_id">Ответственный менеджер</label>
        <b-form-select
          :disabled="disabled"
          id="item-manager_id"
          v-model="form.manager_id"
          :options="staffOptions"
          class="mb-1"
        ></b-form-select>
      </div>
    </template>
    <template #modal>
      <b-modal
        :id="modalId"
        :title="modalConfig.title"
        centered
        busy
      >
        <template #modal-footer="{ close }">
          <b-button
            ref="success-button"
            variant="success"
            slot="modal-footer"
            @click="onDelete"
            :disabled="disabled"
          >{{ modalConfig.success }}</b-button>
          <b-button
            ref="cansel-button"
            variant="danger"
            slot="modal-footer"
            @click="close()"
            :disabled="disabled"
          >отмена</b-button>
        </template>
      </b-modal>
    </template>
  </app-card>
</template>

<script>
import appCard from '@/components/common/pages/Card';
import {mapActions, mapMutations, mapState} from 'vuex';
import {OrderActionTypes, OrderNameSpace} from '@/store/modules/order/types';
import {SourceActionTypes, SourceNameSpace} from '@/store/modules/source/types';
import {DeliveryActionTypes, DeliveryNameSpace} from '@/store/modules/delivery/types';
import {StaffActionTypes, StaffNameSpace} from '@/store/modules/staff/types';
import {ClientActionTypes, ClientNameSpace} from '@/store/modules/client/types';
import {RecipientActionTypes, RecipientNameSpace} from '@/store/modules/recipient/types';
import {CityActionTypes, CityNameSpace} from '@/store/modules/city/types';
import {RootMutationTypes} from '@/store/types';
import {AuthNameSpace} from '@/store/modules/auth/types';
import {modalConfig, modalMode} from '@/config/order';
import {deliveryTypeCollection, orderPaymentStatusCollection, orderStatusCollection} from '@/config/shared';
import {throttle} from 'lodash';
import {duration, phoneMask} from '@/config';
import {loadYmap} from 'vue-yandex-maps'
import {settings} from '@/libs/yandex-maps-settings';
import {getSuggestions} from '@/libs/yandex-maps-controller';
import {phoneSerialize} from '@/utils/serialization';
import {fromUnixTimeStamp} from '@/utils/time-format';
import {phoneToMaskView} from '@/utils/phone-format';

export default {
  name: 'OrderBaseItem',
  components: {
    appCard,
  },
  data: function() {
    return {
      addressSuggestView: null,

      orderPaymentStatusCollection,
      orderStatusCollection,
      deliveryTypeCollection,
      form: {
        id: null,
        source: {},
        number: '',
        number_daily: null,
        client: {
          id: '',
          full_name: '',
          phone: '',
        },
        recipient: {
          id: '',
          full_name: '',
          phone: '',
        },
        florist_id: null,
        delivery_date: '',
        time: {
          from: {
            h: '',
            m: '',
          },
          to: {
            h: '',
            m: '',
          },
        },
        interval_from: '',
        interval_to: '',
        delivery_date_from: '',
        delivery_date_to: '',
        delivery_date_from_formated: '',
        delivery_date_to_formated: '',
        status: 1,
        pay_status: null,
        delivery_status: 1,
        delivery_address: '',
        delivery_comment: '',
        delivery_service_id: null,
        courier_phone: null,
        delivery_price: null,
        courier_id: null,
        manager_id: null,
      },
      addClient: false,
      addRecipient: false,
      isEdit: false,
      modalId: 'order-modal',
      modalConfig,
      modalMode,
      phoneMask,
      dateFormat: {
        year: '2-digit',
        month: '2-digit',
        day: 'numeric',
        weekday: 'short',
      },
      validation: {
        source: null,
        number: null,
        client_full_name: null,
        client_phone: null,
        delivery_date: null,
      },
      validateTime: {
        from: {
          h: null,
          m: null,
        },
        to: {
          h: null,
          m: null,
        },
      },
    };
  },
  computed: {
    ...mapState(OrderNameSpace, {
      order: 'order',
    }),
    ...mapState(SourceNameSpace, {
      sources: 'sources',
    }),
    ...mapState(DeliveryNameSpace, {
      deliveries: 'deliveries',
    }),
    ...mapState(StaffNameSpace, {
      staff: 'staffCollection',
    }),
    ...mapState(ClientNameSpace, {
      clients: 'clients',
      client: 'client',
    }),
    ...mapState(RecipientNameSpace, {
      recipients: 'recipientCollection',
      recipient: 'recipient',
    }),
    ...mapState(CityNameSpace, {
      cities: 'cities',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    sourceOptions() {
      if (this.sources) {
        return this.sources.filter(item => item.status).map(item => {
          return { value: item.id, text: item.name }
        })
      } else {
        return []
      }
    },
    disabled() {
      return this.userData.role === 'florist' || this.userData.role === 'courier'
    },
    deliveryOptions() {
      const list = this.deliveries.filter(item => item.status).map(item => {
        return { value: item.id, text: item.name }
      })

      return list
    },
    statusesCollection() {
      if (this.form.delivery_status === 0) {
        return this.orderStatusCollection.filter(item => item.value !== 3)
      } else {
        return this.orderStatusCollection
      }
    },
    staffOptions() {
      const managers = this.staff.filter(item => {
        return ((item.city_id === this.active_city) && (item.role !== 'florist' && item.role !== 'courier'))
      })
      return managers.map(item => {
        return { value: item.id, text: item.full_name }
      })
    },
    floristOptions() {
      const managers = this.staff.filter(item => {
        return (item.city_id === this.active_city && item.role === 'florist')
      })
      return managers.map(item => {
        return { value: item.id, text: item.full_name }
      })
    },
    clientsOptions() {
      return this.clients.map(item => {
        return { value: item.id, text: item.full_name }
      })
    },
    recipientsOptions() {
      const result = this.recipients.map(item => {
        return { value: item.id, text: item.full_name }
      })

      result.push({ value: null, text: 'Не выбран' })
      return result
    },
    citiesCollection() {
      const currentCity = this.cities.find(city => {
        return city.id === this.active_city
      })
        ? this.cities.find(city => {
          return city.id === this.active_city
        })
        : { name: '' }
      return currentCity
    },
    courierOptions() {
      const courier = this.staff.filter(item => {
        return (item.role === 'courier') && item.city === this.citiesCollection.name
      })
      return courier.map(item => {
        return { value: item.id, text: item.full_name }
      })
    },
    fields() {
      return this.tableColumns
    },
  },
  async created() {
    await loadYmap(settings)
  },
  async mounted() {
    await this.loadCityCollection({ page: 1, page_size: 100 })
    await this.loadSources({ page: 1, page_size: 100 })
    await this.loadDelivery({ page: 1, page_size: 100 })
    await this.loadStaff({ page: 1, page_size: 100 })
    await this.editInit()
    this.defaultManagerSetup()

    this.addressSuggestView = await getSuggestions('item-delivery_address')
  },
  methods: {
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.CreateOrder]: OrderActionTypes.CreateOrder,
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
      [OrderActionTypes.UpdateOrder]: OrderActionTypes.UpdateOrder,
      [OrderActionTypes.DeleteOrder]: OrderActionTypes.DeleteOrder,
    }),
    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
    }),
    ...mapActions(DeliveryNameSpace, {
      [DeliveryActionTypes.LoadDeliveryCollection]: DeliveryActionTypes.LoadDeliveryCollection,
    }),
    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
    }),
    ...mapActions(ClientNameSpace, {
      [ClientActionTypes.LoadClientCollection]: ClientActionTypes.LoadClientCollection,
      [ClientActionTypes.CreateClient]: ClientActionTypes.CreateClient,
    }),
    ...mapActions(RecipientNameSpace, {
      [RecipientActionTypes.LoadRecipientCollection]: RecipientActionTypes.LoadRecipientCollection,
      [RecipientActionTypes.LoadRecipientByPhone]: RecipientActionTypes.LoadRecipientByPhone,
      [RecipientActionTypes.CreateRecipient]: RecipientActionTypes.CreateRecipient,
    }),
    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityCollection]: CityActionTypes.LoadCityCollection,
    }),
    ...mapMutations({
      [RootMutationTypes.SetErrorMessage]: RootMutationTypes.SetErrorMessage,
    }),
    loadOrder: throttle(async function({ id }) {
      await this[OrderActionTypes.LoadOrder]({ id })
    }, duration),
    loadSources: throttle(async function({ page, page_size }) {
      await this[SourceActionTypes.LoadSourceCollection]({ page, page_size })
    }, duration),
    loadDelivery: throttle(async function({ page, page_size }) {
      await this[DeliveryActionTypes.LoadDeliveryCollection]({ page, page_size })
    }, duration),
    loadStaff: throttle(async function({ page, page_size }) {
      await this[StaffActionTypes.LoadStaffCollection]({ page, page_size })
    }, duration),
    loadClients: throttle(async function({ page, phone_search, sort }) {
      await this[ClientActionTypes.LoadClientCollection]({ page, phone_search, sort })
    }, duration),
    loadRecipients: throttle(async function({ page, page_size, phone }) {
      await this[RecipientActionTypes.LoadRecipientCollection]({ page, page_size, phone })
    }, duration),
    loadRecipientByPhone: throttle(async function({ page, phone }) {
      await this[RecipientActionTypes.LoadRecipientByPhone]({ page, phone })
    }, duration),
    loadCityCollection: throttle(async function({ page, page_size }) {
      await this[CityActionTypes.LoadCityCollection]({ page, page_size })
    }, duration),

    async onLoadClients(context) {
      if (phoneSerialize(context).length === 11) {
        await this.loadClients({ page: 1, phone_search: phoneSerialize(context) })
        if (this.clients && this.clients.length) {
          const { id, full_name } = this.clients[0]
          this.form.client.id = id
          this.form.client.full_name = full_name
          this.addClient = false
        } else {
          this.form.client.id = ''
          // this.form.client.full_name = ''
          this.addClient = true
        }
      }
    },
    async onAddClient() {
      const { full_name, phone } = this.form.client
      const result = await this[ClientActionTypes.CreateClient]({
        full_name: full_name,
        phone: phoneSerialize(phone),
        city_id: this.active_city,
      })
      if (result) {
        const { id, full_name, phone } = this.client
        this.form.сlient = id
        this.form.client.id = id
        this.form.client.full_name = full_name
        this.form.client.phone = phoneSerialize(phone)
        this.addClient = false
        return true
      } else return false
    },

    async onLoadRecipients(context) {
      if (phoneSerialize(context).length === 11) {
        await this.loadRecipientByPhone({ page: 1, phone: phoneSerialize(context) })
        if (this.recipient && this.recipient.length) {
          const { id, full_name } = this.recipient[0]
          this.form.recipient.id = id
          this.form.recipient.full_name = full_name
          this.addRecipient = false
        } else {
          this.form.recipient.id = ''
          // this.form.recipient.full_name = ''
          this.addRecipient = true
        }
      }
    },
    async onAddRecipient() {
      const { full_name, phone } = this.form.recipient
      const result = await this[RecipientActionTypes.CreateRecipient]({
        full_name,
        phone: phoneSerialize(phone),
        city_id: this.active_city,
      })
      if (result) {
        this.form.recipient = { ...this.recipient }
        return true
      } else return false
    },

    async onAdd() {
      // Create Order
      if (this.onValidate()) {
        // Сохранение клиента и получателя
        if (this.addClient) {
          const result = await this.onAddClient()
          if (!result) return
        }
        if (this.addRecipient) {
          const result = await this.onAddRecipient()
          if (!result) return
        }

        this.clearUnusedFields()

        const addressValue = document.getElementById('item-delivery_address').value
        this.form.delivery_address = addressValue

        const result = await this[OrderActionTypes.CreateOrder]({
          ...this.form,
          source: this.form.source.id,
          client_id: this.form.client.id,
          recipient_id: this.form.recipient.id,
          delivery_date_from: this.form.delivery_date_from_formated,
          delivery_date_to: this.form.delivery_date_to_formated,
          city_id: this.active_city,
          courier_phone: this.form.courier_phone ? phoneSerialize(this.form.courier_phone) : '',
        })

        for (const key in this.validation) {
          if (Object.hasOwnProperty(key)) {
            this.validation[key] = null
          }
        }

        if (result) {
          await this.goTo({ ...this.order.data })
          this.isEdit = true

          await this.editInit()
        }
      }
    },

    async onEdit() {
      // Edit Order
      if (this.onValidate()) {
        // Сохранение клиента и получателя
        if (this.addClient) {
          const result = await this.onAddClient()
          if (!result) return
        }
        if (this.addRecipient) {
          const result = await this.onAddRecipient()
          if (!result) return
        }

        this.clearUnusedFields()

        const addressValue = document.getElementById('item-delivery_address').value
        this.form.delivery_address = addressValue

        this.form.id = this.$route.params.id

        await this[OrderActionTypes.UpdateOrder]({
          ...this.form,
          source: this.form.source.id,
          client_id: this.form.client.id ? this.form.client.id : '',
          recipient_id: this.form.recipient.id ? this.form.recipient.id : '',
          delivery_date_from: this.form.delivery_date_from_formated ? this.form.delivery_date_from_formated : '',
          delivery_date_to: this.form.delivery_date_to_formated ? this.form.delivery_date_to_formated : '',
          city_id: this.active_city ? this.active_city : '',
          courier_phone: this.form.courier_phone ? phoneSerialize(this.form.courier_phone) : '',
        })
        // Reload after update
        await this.editInit();
      }
    },
    async editInit() {
      // Edit Fields Setup
      if (this.$route.params.id && this.$route.params.id !== 'new') {
        this.isEdit = true
        await this.loadOrder({ ...this.$route.params })
        const time = {
          from: { h: '', m: '' },
          to: { h: '', m: '' },
        }

        if (this.order.interval_from) {
          time.from = {
            h: fromUnixTimeStamp(this.order.interval_from).split(':')[0],
            m: fromUnixTimeStamp(this.order.interval_from).split(':')[1],
          }
        }
        if (this.order.interval_to !== this.order.interval_from) {
          time.to = {
            h: fromUnixTimeStamp(this.order.interval_to).split(':')[0],
            m: fromUnixTimeStamp(this.order.interval_to).split(':')[1],
          }
        } else {
          time.to = {
            h: fromUnixTimeStamp(this.order.interval_from).split(':')[0],
            m: fromUnixTimeStamp(this.order.interval_from).split(':')[1],
          }
        }

        this.form = {
          ...this.order,
          source: this.order.source ? this.order.source : [],
          client: this.order.client ? { ...this.order.client, phone: this.phoneToMaskView({ phone: this.order.client.phone }) } : {},
          recipient: this.order.recipient ? { ...this.order.recipient, phone: this.phoneToMaskView({ phone: this.order.recipient.phone }) } : {},
          time: time,
          delivery_date: this.order.interval_from ? new Date(this.order.interval_from * 1000) : '',
          courier_phone: this.order.courier_phone,
          created_at: this.order.created_at ? this.$dayjs(this.order.created_at * 1000).format('DD.MM.YYYY') + ' ' + this.$dayjs(this.order.created_at * 1000).format('HH:mm') : '',
        }
      }
    },

    onDeleteModal() {
      this.$bvModal.show(this.modalId)
      this.modalMode.delete = true
      this.modalConfig.title = `Подтвердите удаление заказа`
      this.modalConfig.success = 'удалить'
    },
    async onDelete() {
      await this[OrderActionTypes.DeleteOrder]({ ...this.$route.params })
      this.$bvModal.hide(this.modalId)
      this.modalMode.delete = false
      this.goTo()
    },
    onCansel() {
      this.goTo()
    },

    onValidate() {
      ['source', 'number', 'client_full_name', 'client_phone', 'delivery_date'].forEach(item => {
        if (typeof this.$refs[item].value) {
          if (!this.$refs[item].value) {
            this.validation[item] = false
          } else {
            this.validation[item] = null
          }
        }
      })

      this.form.delivery_date_from_formated = this.dateSetup({ key: 'from' })
      this.form.delivery_date_to_formated = this.dateSetup({ key: 'to' })

      const result = Object.values(this.validation).includes(false) ||
        Object.values(this.validateTime.from).includes(false) ||
        Object.values(this.validateTime.to).includes(false)
      if (result) return false
      else return true
    },

    onDeliveryServiceId(id) {
      if (id) {
        this.form.delivery_service_id = id
        this.form.courier_id = null
      } else {
        this.form.delivery_service_id = null
        this.form.courier_phone = ''
      }
    },
    async onSuggestions(val) {
      this.form = { ...this.form, delivery_address: val }
    },
    async goTo({ id } = {}) {
      await this.$router.push(`/order-base${id ? `/${id}` : ''}`)
    },
    phoneToMaskView({ phone }) {
      return phoneToMaskView({ phone })
    },
    dateSetup({ key }) {
      const { h, m } = this.form.time[key]
      const error_message = 'Поле должно содержать две цифры'
      let valid = true

      if (h.length === 1) {
        this[RootMutationTypes.SetErrorMessage]({ message: error_message, type: 'error' })
        this.validateTime[key].h = false
        valid = false
      } else if (h > 23) {
        this[RootMutationTypes.SetErrorMessage]({ message: 'Значение "Часы" не может быть больше 23', type: 'error' })
        this.validateTime[key].h = false
        valid = false
      } else if (!h.length && m.length) {
        this[RootMutationTypes.SetErrorMessage]({ message: 'Поле "Часы" не может быть пустым, если заполнены минуты', type: 'error' })
        this.validateTime[key].h = false
        valid = false
      } else {
        this.validateTime[key].h = null
      }


      if (h.length && !m.length) {
        this[RootMutationTypes.SetErrorMessage]({ message: 'Поле "Минуты" обязательно для заполнения, если поле "Часы" заполненно', type: 'error' })
        this.validateTime[key].m = false
        valid = false
      } else if (h.length && m.length === 1) {
        this[RootMutationTypes.SetErrorMessage]({ message: error_message, type: 'error' })
        this.validateTime[key].m = false
        valid = false
      } else if (h.length && m > 59) {
        this[RootMutationTypes.SetErrorMessage]({ message: 'Значение "Минуты" не может быть больше 59', type: 'error' })
        this.validateTime[key].m = false
        valid = false
      } else {
        this.validateTime[key].m = null
      }

      if (valid) {
        if (!h.length || !m.length) {
          this[RootMutationTypes.SetErrorMessage]({ message: 'Заполните обязательные поля: "Время с" и "Время по"', type: 'error' })
          this.validateTime[key].h = false
          this.validateTime[key].m = false
          valid = false
        } else {
          this.validateTime[key].h = true
          this.validateTime[key].m = true
        }
      }

      if (valid) {
        const d = new Date(this.form.delivery_date)
        d.setHours(h)
        d.setMinutes(m)

        return d.getTime() / 1000
      } else return false
    },
    defaultManagerSetup() {
      if (!this.isEdit) {
        this.form.manager_id = this.staffOptions.find(manager => manager.value === this.userData.id).value
      }
    },
    clearUnusedFields() {
      if (this.form.delivery_status === 0) {
        this.form.delivery_address = ''
        this.form.delivery_comment = ''
        this.form.delivery_service_id = ''
        this.form.courier_phone = ''
        this.form.courier_id = ''
        this.form.delivery_price = ''
      } else if (this.form.delivery_status === 1) {
        this.form.delivery_service_id = ''
        this.form.courier_phone = ''
      } else if (this.form.delivery_status === 2) {
        this.form.courier_id = ''
      }
    },
  },
  watch: {
    'form.delivery_status': {
      async handler() {
        this.clearUnusedFields()
      },
    },
    'form.delivery_status': {
      handler(val) {
        if (val === 0 && this.form.status === 3) this.form.status = 1
        if (val === 0) this.form.delivery_price = null
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.order-info-icon {
    cursor: pointer;
}
</style>
