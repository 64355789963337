<template>
  <section>
    <app-total
      title="Товары в заказе"
      :item-count="order && order.products_count ? order.products_count : ''"
      :total-price="order && order.products_total_price ? order.products_total_price : ''"
    ></app-total>
    <app-card
      v-bind="{
        hideAdmit: hasPermission
      }"
      hide-decline
      hide-title
      admit-name="Добавить товар"
      admit-variant="danger"
      @admit="onAddModal"
      class="p-0"
    >
      <template #body>
        <table-body
          :fields="fields"
          class="p-0 mb-2 w-100"
          :is-column-settings="false"
          :is-per-page="false"
          :page-size="null"
        >
          <b-table
            class="position-relative"
            v-bind="{
              items: products,
              fields,
              'per-page': page_size,
              'current-page': page,
              striped,
              responsive,
              'status-colored': true
            }"
          >
            <template #cell(id)="data">
              <span>{{ data.item.id }}</span>
            </template>
            <template #cell(photo)="data">
              <img
                :src="`${BASE_URL}${data.item.photo}`"
                style="height: 50px; width:auto; margin-bottom: 5px;"
                @click="onImageOpen(`${BASE_URL}${data.item.photo}`)"
              >
            </template>

            <template #cell(actions)="row">
              <table-buttons
                v-bind="{
                  disabled: hasPermission
                }"
                @edit="onEditModal({ ...row.item })"
                @delete="onDeleteModal({ ...row.item })"
              ></table-buttons>
            </template>
          </b-table>
        </table-body>
      </template>
      <template #modal>
        <b-modal
          :id="modalId"
          :title="modalConfig.title"
          centered
          busy
          @hide="onModalClose"
        >
          <template v-if="modalMode.add || modalMode.edit">
            <label for="item-photo">Фото товара</label>
            <b-form-file
              v-model="selectedFile"
              :state="Boolean(selectedFile)"
              @input="onAddFile"
              class="mb-1"
              placeholder="Выберите или перетащите файл..."
            ></b-form-file>
            <div>
              <template v-if="form.photo">
                <img
                  :src="`${BASE_URL}${form.photo}`"
                  class="img-thumbnail"
                />
              </template>
            </div>

            <label for="item-composition_comment">Комментарий к композиции</label>
            <b-form-textarea
              id="item-composition_comment"
              v-model="form.composition_comment"
              class="mb-1"
            ></b-form-textarea>

            <label for="item-date">Сумма за товар</label>
            <b-form-input
              id="item-price"
              v-model="form.price"
              :state="validation.price"
              class="mb-1"
            ></b-form-input>

            <label for="item-postcard_text">Текст открытки</label>
            <b-form-textarea
              id="item-postcard_text"
              v-model="form.postcard_text"
              class="mb-1"
            ></b-form-textarea>
          </template>

          <template #modal-footer="{ close }">
            <b-button
              ref="success-button"
              variant="success"
              slot="modal-footer"
              @click="modalAction"
              :disabled="isLoading"
            >
              {{ modalConfig.success }}
            </b-button>
            <b-button
              ref="cansel-button"
              variant="danger"
              slot="modal-footer"
              @click="close()"
            >
              отмена
            </b-button>
          </template>
        </b-modal>

        <b-modal
          :id="imgModalId"
          centered
          modal-class="app-modal-img"
          hide-footer
          hide-header
          size="lg"
        >
          <div><img :src="imageUrl" /></div>
        </b-modal>
      </template>
    </app-card>
  </section>
</template>

<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import Card from '@/components/common/pages/Card'
import Total from '@/components/common/table/OrdersTotal.vue'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'
import { tableColumns, modalConfig, modalMode } from '@/config/order-items'
import { OrderActionTypes, OrderNameSpace } from '@/store/modules/order/types'
import { FileNameSpace, FileActionTypes } from '@/store/modules/file/types'
import { RootMutationTypes } from '@/store/types'
import { mapState, mapActions, mapMutations } from 'vuex'
import { throttle } from 'lodash'
import { duration, BASE_URL } from '@/config'

export default {
  name: 'OrderItems',

  components: {
    'app-card': Card,
    'app-total': Total,
    TableBody,
    TableButtons,
  },
  data() {
    return {
      selectedFile: null,
      form: {
        photo: '',
        postcard_text: '',
        composition_comment: '',
        price: '',
        id: '',
      },
      tableColumns,
      modalConfig,
      modalMode,
      page_size: 10,
      page: 1,
      modalId: 'order-item-modal',
      imgModalId: 'img-modal',
      imageUrl: '',
      BASE_URL,
      isLoading: false,
      validation: {
        photo: null,
        price: null,
      }
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(OrderNameSpace, {
      order: 'order',
      product: 'product',
    }),
    ...mapState(FileNameSpace, {
      files: 'files',
      file: 'file',
    }),
    orderId() {
      return this.order ? this.order.id : ''
    },
    fields() {
      return this.tableColumns
    },
    products() {
      return this.order ? this.order.products : []
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },
  async mounted() {
    await this.loadOrder(this.$route.params)
  },
  methods: {
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
      [OrderActionTypes.CreateOrderProduct]: OrderActionTypes.CreateOrderProduct,
      [OrderActionTypes.UpdateOrderProduct]: OrderActionTypes.UpdateOrderProduct,
      [OrderActionTypes.DeleteOrderProduct]: OrderActionTypes.DeleteOrderProduct,
    }),
    ...mapActions(FileNameSpace, {
      [FileActionTypes.LoadFile]: FileActionTypes.LoadFile,
    }),
    ...mapMutations({
      [RootMutationTypes.SetErrorMessage]: RootMutationTypes.SetErrorMessage,
    }),
    loadOrder: throttle(async function({ id }) {
      await this[OrderActionTypes.LoadOrder]({ id })
    }, duration),

    onAddModal() {
      this.$bvModal.show(this.modalId)
      this.modalMode.add = true
      this.modalConfig.title = `Добавление товара в заказ`
      this.modalConfig.success = 'добавить'
    },
    async onAdd() {
      if (this.validate()) {
        this.isLoading = true
        await this[OrderActionTypes.CreateOrderProduct]({ order_id: this.orderId, ...this.form })
        this.$bvModal.hide(this.modalId)
        this.onModalClose()
        this.isLoading = false
        await this[OrderActionTypes.LoadOrder]({ id: this.orderId })
      }
    },

    async onEditModal({ id }) {
      this.$bvModal.show(this.modalId)
      this.modalMode.edit = true
      this.modalConfig.title = `Редактировать товар`
      this.modalConfig.success = 'изменить'
      const result = this.order.products.find(product => {
        return product.id === id
      })
      this.form = { ...this.form, ...result }
    },
    async onEdit() {
      if (this.validate()) {
        this.isLoading = true
        await this[OrderActionTypes.UpdateOrderProduct]({ ...this.form, order_id: this.orderId })
        this.$bvModal.hide(this.modalId)
        this.loadOrder({ id: this.orderId })
        this.onModalClose()
        this.isLoading = false
      }
    },

    onDeleteModal({ id }) {
      this.$bvModal.show(this.modalId)
      this.form.id = id
      this.modalMode.delete = true
      this.modalConfig.title = `Удалить товар из заказа`
      this.modalConfig.success = 'удалить'
    },
    async onDelete({ id }) {
      const result = await this[OrderActionTypes.DeleteOrderProduct]({ id })
      if (result) {
        this.loadOrder({ id: this.orderId })
        this.$bvModal.hide(this.modalId)
        this.onModalClose()
      }
    },

    async onAddFile() {
      await this[FileActionTypes.LoadFile]({ file: this.selectedFile })
      this.form.photo = this.file.path
    },
    modalAction() {
      if (this.modalMode.add) return this.onAdd()
      if (this.modalMode.edit) return this.onEdit()
      if (this.modalMode.delete) return this.onDelete({ id: this.form.id })
    },
    onModalClose() {
      for (const key in this.modalMode) {
        if (this.modalMode.hasOwnProperty(key)) {
          this.modalMode[key] = ''
        }
      }
      for (const key in this.modalConfig) {
        if (this.modalConfig.hasOwnProperty(key)) {
          this.modalConfig[key] = ''
        }
      }
      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = ''
        }
      }
    },
    onImageOpen(path) {
      this.$bvModal.show(this.imgModalId)
      this.imageUrl = path
    },
    validate() {
      if (this.form.price) {
        this.validation.price = null
        this.isValid = true
      } else {
        this.validation.price = false
        this[RootMutationTypes.SetErrorMessage]({ type: 'error', message: 'Сумма за товар обязательно для заполнения' })
        return false
      }
      
      if (this.form.photo) {
        this.isValid = true
        this.validation.photo = null
      } else {
        this.validation.photo = false
        this[RootMutationTypes.SetErrorMessage]({ type: 'error', message: 'Фото обязательно для заполнения' })
        return false
      }
      
      return true
    }

  }
}
</script>
