const tableColumns = Object.freeze([
  {
    key: 'product_photo',
    label: 'Фото продукта',
  },
  {
    key: 'amount',
    label: 'Сумма товара',
  },
  {
    key: 'text_of_card',
    label: 'Текст открытки',
  },
  {
    key: 'comment',
    label: 'Коментарий',
  },
  {
    key: 'actions',
    label: '',
  },
])

const actions = Object.freeze([
  {
    key: 'edit',
    variant: 'primary',
    icon: 'EditIcon',
    to: { name: 'order-base-item', params: ['id'] },
  },
])

const modalMode = {
  edit: false,
  add: false,
  delete: false,
}

const modalConfig = {
  title: '',
  success: '',
  danger: '',
}

export { tableColumns, actions, modalConfig, modalMode }
