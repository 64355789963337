export const getCoords = async query => {
  const res = await new ymaps.geocode(query, { results: 1 })

  const geoObj = res.geoObjects.get(0)
  const myCoords = {
    1: geoObj.geometry.getCoordinates()[0],
    2: geoObj.geometry.getCoordinates()[1],
  }

  return myCoords
}

export const getSuggestions = async selector => {
  let suggestions = null

  while (!suggestions) {
    suggestions = await tryInitializeSuggestions(selector)
  }

  return suggestions
}

async function tryInitializeSuggestions(selector) {
  const executor = resolve => initializeSuggestionsExecutor(resolve, selector)
  return new Promise(executor)
}

async function initializeSuggestionsExecutor(resolve, selector) {
  try {
    const res = await getInitializedSuggestions(selector)
    resolve(res)
  } catch {
    setTimeout(() => resolve(null), 100)
  }
}

async function getInitializedSuggestions(selector) {
  return new ymaps.SuggestView(selector);
}
