import dayjs from '@/libs/day'

export const toUnixTimestamp = (date, time) => {
  let result = dayjs(date).format('YYYY.MM.DD') + ' ' + time
  result = dayjs(result).$d
  result = dayjs(result).unix()
  return result
}

export const fromUnixTimeStamp = time => {
  const date = new Date(time * 1000)
  const h = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  const m = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  const s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
  const result = `${h}:${m}:${s}`
  return result
}
